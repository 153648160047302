import { IonPage } from "@ionic/react";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import {
  PaymentServiceAccountStatus,
  PayrollList,
  Summary as SummaryType,
} from "@src/lib/interface";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { fetchLifeTimeEarnings, fetchPayrollData } from "./api";
import { ShiftList } from "./shiftList";
import { ShiftListHeader } from "./shiftListHeader";
import { ShiftListLoader } from "./shiftListLoader";
import { Summary } from "./summary";
import { USER_EVENTS } from "../../constants/userEvents";
import { formatDollarsAsUsd } from "../../utils/currency";
import { useShouldBeOnboarded } from "../onboardingStripe/hooks";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { useDefinedAgent } from "../store/helperHooks";

const getWeeklyTotalEarnings = (payroll: PayrollList[]) => {
  const total = payroll.reduce((total, shift) => (total += shift.totalAmount), 0);
  return formatDollarsAsUsd(total);
};

const getWeeklyTotalHours = (payroll: PayrollList[]) =>
  payroll.reduce((total, shift) => (total += shift.time), 0).toFixed(2);

const getTotalShifts = (payroll: PayrollList[]) => payroll.length;

const NO_SHIFTS = "No Shifts This Week";
/**
 * @deprecated
 */
export function PayrollPage() {
  const { showSuccessToast } = useToast();
  const { paymentAccountInfo, userId } = useDefinedAgent();
  const [relativeWeek, setRelativeWeek] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [payroll, setPayroll] = useState<PayrollList[]>([]);
  const [summary, setSummary] = useState<SummaryType>({} as SummaryType);
  const [shiftMessage, setShiftMessage] = useState<string>("");

  const handleBackClick = () => setRelativeWeek(relativeWeek - 1);
  const handleForwardClick = () => setRelativeWeek(relativeWeek + 1);

  useEffect(() => {
    async function getLifeTimeEarnings() {
      try {
        const { summary } = await fetchLifeTimeEarnings();
        setSummary(summary);
      } catch (error) {
        setShiftMessage("Sorry, we are experiencing technical difficulties");
      }
    }
    getLifeTimeEarnings();

    if (!paymentAccountInfo) {
      return;
    }

    if (paymentAccountInfo.status === PaymentServiceAccountStatus.NO_DOCUMENTS_REQUIRED) {
      showSuccessToast(
        "Your Stripe account is now in processing! If this takes longer than a few hours, contact us and we'll help."
      );
    }
  }, [paymentAccountInfo, showSuccessToast]);

  useLogEffect(USER_EVENTS.VIEWED_PAYROLL);

  const getPayrollData = useCallback(async () => {
    if (!userId) {
      return;
    }
    setLoading(true);
    setShiftMessage("");
    try {
      const data = await fetchPayrollData({ relativeWeek });
      if (isEmpty(data)) {
        setShiftMessage(NO_SHIFTS);
      } else {
        const message = `Earned ${getWeeklyTotalEarnings(data)} by working
              ${getWeeklyTotalHours(data)} hours in ${getTotalShifts(data)} Shifts`;
        setShiftMessage(message);
      }
      setPayroll(data);
    } catch (error) {
      setShiftMessage("Sorry, we are experiencing technical difficulties");
    }
    setLoading(false);
  }, [relativeWeek, userId]);

  const history = useHistory();
  const shouldBeOnboardedInPaymentService = useShouldBeOnboarded();

  useEffect(() => {
    if (shouldBeOnboardedInPaymentService) {
      history.replace(TabRouterPath.SETUP_STRIPE);
    } else {
      getPayrollData();
    }
  }, [getPayrollData, history, shouldBeOnboardedInPaymentService]);

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Payroll"
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.ACCOUNT} />}
          />
        }
        onRefresh={getPayrollData}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Summary earnings={summary.total || 0} shifts={summary.shifts as number} />
          <div
            style={{
              textAlign: "center",
              marginBottom: "10px",
              overflowY: "scroll",
              height: "70vh",
            }}
          >
            <ShiftListHeader
              shiftMessage={shiftMessage}
              handleBackClick={handleBackClick}
              handleForwardClick={handleForwardClick}
              relativeWeek={relativeWeek}
            />
            {loading ? <ShiftListLoader /> : <ShiftList shifts={payroll} />}
          </div>
        </div>
      </PageWithHeader>
    </IonPage>
  );
}
